import flatpickr from "flatpickr";
import Nederlands from "flatpickr/dist/l10n/nl.js"

const SmoothScroll = require('smooth-scroll')
require('lazysizes')
require('lazysizes/plugins/bgset/ls.bgset')

window.lazySizesConfig = {loadMode: 3}
import GLightbox from 'glightbox'
import Glide, { Controls }  from '@glidejs/glide'
import ArrowDisabler from './arrowDisabler.js'
import ImagesLoadedSameHeight from './imagesLoadedSameHeight.js'


// INITIALIZE LIGHTBOX
// Documentation: https://biati-digital.github.io/glightbox/

const lightbox = GLightbox({
	selector: '.lightbox',
	touchNavigation: true,
	type: 'image',
	loop: true,
});


// INITIALIZE SLIDESHOW
// Documentation: https://glidejs.com/

const glides = []

const singleSliders = document.querySelectorAll('.single-slider');
singleSliders.forEach($slider => {

	if (!$slider.classList.contains('single-slider--start-end')) {

		// Start in the beginning
		const glide = new Glide($slider, {
			type: 'slider',
			animationDuration: 500,
			animationTimingFunc: 'ease-in-out',
			rewind: false,
			startAt: 0,
		}).mount({ ArrowDisabler })
		glides.push(glide)
	} else {
		
		const sliderCount = $slider.querySelectorAll('.glide__slide').length;

		// Start at the end
		const glide = new Glide($slider, {
			type: 'slider',
			animationDuration: 500,
			animationTimingFunc: 'ease-in-out',
			rewind: false,
			startAt: sliderCount - 1,
		}).mount({ ArrowDisabler })
		glides.push(glide)
	}
	
});

const multipleSliders = document.querySelectorAll('.roomdetail-slider');
multipleSliders.forEach($slider => {

	if (!$slider.classList.contains('single-slider--start-end')) {

		// Start in the beginning
		const glide = new Glide($slider, {
			type: 'carousel',
			animationDuration: 500,
			animationTimingFunc: 'ease-in-out',
			rewind: true,
			startAt: 0,
			gap: 0,
		}).mount()
		glides.push(glide)
	}
	
});


// FAQ OPEN AND CLOSED
const faqs = document.querySelectorAll('.js--faq-question');

faqs.forEach($faq_question => {
	const faq_id = $faq_question.dataset.id;
	const $faq_answer = document.querySelector(`.js--faq-answer--${faq_id}`);

	$faq_question.addEventListener('click', function(){
		$faq_question.classList.toggle('open');
		$faq_answer.classList.toggle('show');
	});
});


// INITIALIZE DATEPICKER
// Documentation: https://github.com/flatpickr/flatpickr

const $inputArrivalDate = document.querySelector('.js--arrival_date');
const flatpickrArrivalDate = flatpickr($inputArrivalDate, 
	{
		enableTime: true,
		altInput: true,
		dateFormat: "Y-m-d",
		altFormat: "j F Y",
		allowInput: true,
		minDate: "today",
		"locale": Nederlands.nl,
	});  // flatpickr

// flatpickrArrivalDate.minuteElement.style.display = "none"

const $inputDepartureDate = document.querySelector('.js--departure_date');
const flatpickrDepartureDate = flatpickr($inputDepartureDate,  
	{
		enableTime: true,
		altInput: true,
		dateFormat: "Y-m-d",
		altFormat: "j F Y",
		allowInput: true,
		minDate: "today",
		"locale": Nederlands.nl,
	});  // flatpickr

// flatpickrDepartureDate.minuteElement.style.display = "none"


// INITIALIZE SMOOTHSCROLL
new SmoothScroll('a[href*="#"]', {
	speed: 1000
})


// MOBILE MENU OPEN AND CLOSE
const $menuTriggerBttn = document.querySelector('.js--mobile-menu-trigger');
const $menuCloseBttn = document.querySelector('.js--mobile-menu-close');
const $mobileMenu = document.querySelector('.js--mobile-menu');

$menuTriggerBttn.addEventListener('click', function(){
	$mobileMenu.classList.toggle('show');
});

$menuCloseBttn.addEventListener('click', function(){
	$mobileMenu.classList.toggle('show');
});


// BOOKINGS MODDAL OPEN AND CLOSE
const bookModalTriggerBttns = document.querySelectorAll('.js--bookings-modal-trigger');
const $bookModalCloseBttn = document.querySelector('.js--bookings-modal-close');
const $bookModal = document.querySelector('.js--bookings-modal-wrapper');

bookModalTriggerBttns.forEach($bookModalTriggerBttn => {
	$bookModalTriggerBttn.addEventListener('click', function(){
		// close mobile menu if open
		$mobileMenu.classList.remove('show');
	
		$bookModal.classList.add('show');
	});
});

$bookModalCloseBttn.addEventListener('click', function(){
	$bookModal.classList.remove('show');
});


// HOME HEADER IMG BG CHANGE
const homeNavBttns = document.querySelectorAll('.js-home-nav-bttn');
const homeImgs = document.querySelectorAll('.js-home-img');

homeNavBttns.forEach($homeNavBttn => {
	const homeNavBttnId = $homeNavBttn.dataset.nav_id;

	$homeNavBttn.addEventListener('mouseover', function(){
		homeImgs.forEach($homeImg => {
			const homeImgId = $homeImg.dataset.img_id;

			if (homeNavBttnId === homeImgId ) {
				$homeImg.classList.add("show");
			} else {
				$homeImg.classList.remove("show");
			}
		});
	})
});


// INPUT + MIN/PLUS BUTTONS
const $numberInput = document.querySelector('.js--number-input');
const $numberMinBttn = document.querySelector('.js--number-input-min ');
const $numberPlusBttn = document.querySelector('.js--number-input-plus');

if (($numberInput !== null) && ($numberMinBttn !== null)) {
    $numberMinBttn.addEventListener('click', function(e){
	    e.preventDefault()

	    if (parseFloat($numberInput.value) > 1) {
		    $numberInput.value = parseFloat($numberInput.value) - 1;
	    }
    });
}

if (($numberInput !== null) && ($numberPlusBttn !== null)) {
    $numberPlusBttn.addEventListener('click', function(e){
	    e.preventDefault()
	    $numberInput.value = parseFloat($numberInput.value) + 1;
    });
}