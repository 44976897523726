function ImagesLoadedSameHeight (Glide, Components, Events) {
  return {
    mount() {

      Glide.on(['mount.after', 'run'], () => {
        
        const slides = document.querySelectorAll('.glide__slide');

        slides.forEach($slide => {
          $slide.style.width = 'unset';
        });
      })
    }
  }
}


export default ImagesLoadedSameHeight;